






























































































































import { Component, Mixins } from 'vue-property-decorator'
import { FormHelper } from '@/mixins'
import Logs from '@/api/Logs'
import { SPLIT_COMMAS_REGEX } from '@/services/Validation/regexes'
import { itemHoverConfig, ItemFactory } from '@/services/Factories/Item/ItemFactory'
import Game from '@/api/Game'

@Component({
  components: {
    ItemIconCard: () => import('../../components/Item/ItemIconCard.vue')
  }
})
export default class BidsInformation extends Mixins(FormHelper) {
  bidId = ''
  logs: any[] = []
  noResults = false

  SPLIT_COMMAS_REGEX = SPLIT_COMMAS_REGEX

  isBidFinished(item) {
    return item.bidderWithdrewItem
        || item.ownerWithdrewGold
        || item.forcedEndDate && new Date() > new Date(item.forcedEndDate)
        || !item.forcedEndDate && new Date() > new Date(item.endDate)
  }

  async searchLog() {
    this.noResults = false

    const { bidId } = this

    try {
      const logs = await Logs.getBidLogs(bidId)

      if (!Array.isArray(logs) || !logs.length) {
        this.noResults = true

        return
      }

      this.logs = logs

      /* for (const [index, log] of logs.entries()) {
        const names = await Game.getItemsNames([log.item.itemInstance.itemVNum])
        const parsedItem = new ItemFactory(log.item).buildItem({
          name: names[0].name,
          iconId: 0,
        })
        const displayShellElement = document.getElementById(`parsedItem-${index}`)

        displayShellElement!.innerHTML = itemHoverConfig(parsedItem, {
          displayGMOptions: true,
          fromShellParser: true,
        }).content()
      } */
    } catch(e) {
      console.error(e)
    }
  }
}
